// Pagination

.page-link {
  border: 0;
  font-size: $pagination-font-size;
  color: $pagination-color;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: $pagination-transition;
  border-radius: $pagination-border-radius;

  &:hover {
    color: $pagination-hover-color;
  }

  &:focus {
    box-shadow: none;
  }
}

.page-item {
  &.active .page-link {
    background-color: $page-item-active-link-bgc;
    color: $page-item-active-link-color;
    border: 0;
    box-shadow: $page-item-active-link-box-shadow;
    transition: $pagination-active-transition;
    font-weight: $page-item-active-link-font-weight;
  }

  &:first-child {
    .page-link {
      border-top-left-radius: $pagination-border-radius;
      border-bottom-left-radius: $pagination-border-radius;
    }
  }

  &:last-child {
    .page-link {
      border-top-right-radius: $pagination-border-radius;
      border-bottom-right-radius: $pagination-border-radius;
    }
  }
  &:not(:first-child) {
    .page-link {
      margin-left: 0;
    }
  }
}

.pagination-lg,
.pagination-sm {
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: $pagination-border-radius;
      border-bottom-left-radius: $pagination-border-radius;
    }
    &:last-child .page-link {
      border-top-right-radius: $pagination-border-radius;
      border-bottom-right-radius: $pagination-border-radius;
    }
  }
}

.pagination-circle {
  .page-item {
    &:first-child {
      .page-link {
        border-radius: $pagination-circle-border-radius;
      }
    }

    &:last-child {
      .page-link {
        border-radius: $pagination-circle-border-radius;
      }
    }
  }
  .page-link {
    border-radius: $pagination-circle-border-radius;
    padding-left: $pagination-circle-padding-x;
    padding-right: $pagination-circle-padding-x;
  }

  &.pagination-lg {
    .page-link {
      padding-left: $pagination-circle-lg-padding-left;
      padding-right: $pagination-circle-lg-padding-right;
    }
  }
  &.pagination-sm {
    .page-link {
      padding-left: $pagination-circle-sm-padding-left;
      padding-right: $pagination-circle-sm-padding-right;
    }
  }
}
