// To be removed

hr.divider-horizontal:not([size]) {
  height: 2px;
}

.divider-horizontal {
  opacity: 1;
  background-color: #f5f5f5;
  height: 2px;
}

.divider-vertical {
  opacity: 1;
  background-color: #f5f5f5;
  display: inline-block;
  width: 2px;
  margin: 0 1rem;
}

hr.divider-horizontal-blurry {
  background-image: linear-gradient(
    90deg,
    transparent,
    hsl(0, 0%, 40%),
    transparent
  );
  background-color: transparent;
}

hr.divider-vertical-blurry {
  background-image: linear-gradient(
    180deg,
    transparent,
    hsl(0, 0%, 40%),
    transparent
  );
  background-color: transparent;
  width: 1px;
  top: 0;
  right: 0;
}
