// Tooltip

// .tooltip {
//   &.show {
//     opacity: 1;
//   }
//
//   .tooltip-arrow {
//     display: none;
//   }
// }

.tooltip-inner {
  color: $tooltip-inner-color;
  padding: $tooltip-inner-padding-y $tooltip-inner-padding-x;
  font-size: $tooltip-inner-font-size;
  background-color: $tooltip-inner-background-color;
  border-radius: $tooltip-inner-border-radius;
  // Additional MDB Angular styles
  opacity: 0;
  // Additional MDB Angular styles
}

// Additional MDB Angular styles
.notification-open .cdk-overlay-container {
  z-index: $zindex-toast;
}
// Additional MDB Angular styles
