//
// Basic MDB table
//

.table {
  font-size: $table-font-size;

  > :not(caption) > * > * {
    padding: $table-cell-padding-y $table-cell-padding-x;
  }

  th {
    font-weight: 500;
  }

  tbody {
    font-weight: 400;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-group-separator-color;
  }
}

.table-sm {
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
  }
}

@each $color, $value in $table-variants {
  @include table-variant-mdb($color, $value);
}

.table-hover {
  > tbody > tr {
    transition: $table-hover-transition;
  }
  > tbody > tr:hover {
    --#{$variable-prefix}table-accent-bg: transparent;
    background-color: var(--#{$variable-prefix}table-hover-bg);
  }
}

.table-group-divider {
  border-top: (2 * $table-border-width) solid;
  border-top-color: inherit;
}

.table-divider-color {
  border-top-color: rgba(0, 0, 0, 0.1);
}
