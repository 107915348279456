// Modal
.modal-content {
  border: 0;
  box-shadow: $modal-box-shadow;
}

.mdb-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.15s linear;
}

.modal-dynamic > :first-child {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-dialog-scrollable {
  .modal-content,
  .modal-content > :first-child {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .modal-body {
    overflow-y: auto;
  }
}
// Additional MDB Angular styles
