// Accordion

.accordion-button {
  &:not(.collapsed) {
    &:focus {
      box-shadow: $accordion-button-focus-box-shadow;
    }
  }

  &:focus {
    border-color: $accordion-button-focus-border-color;
    outline: 0;
    box-shadow: none;
  }
}

.accordion-flush {
  .accordion-button:not(.collapsed) {
    box-shadow: $accordion-flush-button-box-shadow;
  }
  .accordion-item {
    border-bottom: $accordion-flush-item-border-bottom;
  }
}

.accordion-borderless {
  .accordion-item {
    border: 0;
    .accordion-button {
      border-radius: $accordion-borderless-button-border-radius;
      &:not(.collapsed) {
        background-color: $accordion-borderless-button-bgc;
        color: $accordion-borderless-button-color;
        box-shadow: none;
      }
    }
  }
}
