:root {
  --ripplecolor: #ccc;

  --accordion-body-padding-x: 0;
  --accordion-body-padding-y: 0;

  --font-family-base: "Roboto", "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;


  --lms-font-family: "Roboto", "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif;

  --lms-font-size: 0.875rem;
  --line-height-base: 1.3;
  --lms-line-height: 1.3;
  --mdb-body-line-height: 1.3;
  --bs-line-height: 1.3;
  --mdb-gray-50: #fbfbfb;
  --primary: #3f51b6;
  --secondary: #3fb686;
  --pink: #ff4081;
  --success: #51b63f;
  --info: #3f8cb6;
  --warning: #b6a43f;
  --danger: #b63f51;
  --light: #f1f1f1;
  --dark: #0d1024;
  --generic: #3eb283;

  --lms-theme-primary: var(--primary);
  /* theme primary color, change this value to customize theme*/
  --lms-theme-secondary: var(--secondary);
  /* theme secondary color, change this value to customize theme*/
  --mdb-toast-bg: var(--primary);

  --valid-border-color: rgba(66, 169, 72, .7);
  --valid-border-left-color: var(--success);
  --invalid-border-color: var(--danger);
  --invalid-border-left-color: rgba(169, 68, 66, 0.706);

  --lms-text: #5a5a5a;
  /* --lms-text: #232323; */
  --lms-input: #5a5a5a;
  --lms-inverted-background: #332d2d;
  --lms-background: #f5f5f5;
  /* grey-100 */
  --lms-panel-background: #f0f0f0;
  --lms-input-background: white;
  --lms-element-background: #3f51b6;
  --lms-element-text: #fafafa;
  --lms-input-height: 31px;

  --lms-font-size: 0.875rem;
  --font-size-base: 0.875rem;
  --font-sizes: (1: --font-size-base*1.9,
      /*26 h1 */
      2: --font-size-base*1.7,
      /*24 h2 */
      3: --font-size-base*1.5,
      /*22 h3 */
      4: --font-size-base*1.25,
      /*20  h4 */
      5: --font-size-base*1.1,
      /*17  body*/
      6: --font-size-base*0.9,
      /*14 muted text*/
    );
}

@import url("assets/css/mdb.min.css");
@import url("assets/toastr.css");

body {
  background-color: var(--lms-background);
  color: var(--mdb-body-color);
}

address,
dl,
ol,
p,
ul {
  margin-bottom: 1rem
}

p {
  padding: 10px;
}

pre,
textarea {
  overflow: auto;
}

small,
.label-small {
  font-size: 90%;
}


.mat-date-range-input-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
}

.mat-date-range-input-wrapper {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.mat-date-range-input-wrapper>input {
  flex-grow: 1;
  padding-top: 0.1rem !important;
  margin-top: 0.1rem !important;
  min-width: 100px;
  width: 100px;
}

input:not([type=checkbox]):not([type=radio]):not([type=date]):not(.not),
.form-control:not(mat-radio-group) {
  border: 1px solid var(--mdb-border-color);
  margin-top: 0.04rem;
  margin-left: 0.03rem;
  /* padding: .3em .5em;
  color: var(--lms-text); */
}


.form-check-input[type=radio] {
  margin-right: inherit;
  width: 1.29rem;
  height: 1.28rem;
  ;
}

.form-check {
  padding-left: 1.35rem;
}

input[type="file"] {
  border: inherit;
  cursor: pointer;
}

input {
  border: var(--mdb-border-color) 1px solid;
  border-radius: var(--mdb-border-radius-sm);
}

input,
optgroup,
select,
textarea {
  transition: border-color 0.3s ease-out;
}

/* input:focus,
.input:focus {
  border: 1px solid #5A7EC7;
  border-width: 1px 1px 1px 3px;
  transition: all 0.3s ease-out;
} */

input:not([type=checkbox]):not([type=radio]):not([type=date]):not(.not),
optgroup,
textarea:not(.not) {
  line-height: 1.3 !important;
  border: 1px solid var(--mdb-border-color);
  border-radius: var(--mdb-border-radius-sm);
  height: var(--lms-input-height);
  /* background-color: var(--lms-input-background); */
  padding: .3em .5em;
  transition: border-color 0.3s ease-out;
}

mat-select {
  padding: .35rem 0.65rem .35rem .3rem;
  cursor: pointer;
  width: 100%;
  min-width: 175px;
  margin-left: 0.04rem;
  height: var(--lms-input-height);
  background-color: var(--lms-input-background);
  border: 1px solid var(--mdb-border-color);
  border-radius: var(--mdb-border-radius-sm);
  color: var(--lms-text);
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease-out;
}

select,
select.lmsselect {
  cursor: pointer;
  width: 100%;
  min-width: 175px;
  font-size: var(--lms-font-size);
  margin-left: 0.03rem;
  height: var(--lms-input-height);
  padding: .35rem 0.65rem .35rem .3rem;
  background-color: var(--lms-input-background);
  border: 1px solid var(--mdb-border-color);
  border-radius: var(--mdb-border-radius-sm);
  color: var(--lms-text);
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease-out;
}

select.lmsselect:focus {
  background: var(--lms-input-background);
  border: 1px solid #5A7EC7;
  border-radius: var(--mdb-border-radius-sm);
}

select.lmsselect ::after {
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  background-color: var(--lms-text);
  clip-path: polygon(8% 17%, 0% 25%, 50% 84%, 100% 25%, 92% 17%, 50% 65%);
}

input[type="file"] {
  border: inherit;
  cursor: pointer;
}

@media (max-width: 991px) {
  .container {
    max-width: 95% !important;
  }

  .mat-expansion-panel-body {
    margin-top: 0.25rem;
    padding: 0.25rem 0.01rem 0.01rem !important;
  }
}

.mdb-select-value {
  color: var(--lms-text);
  box-sizing: content-box;
  display: inherit !important;
  align-items: left;
  cursor: pointer;
  width: 100%;
  font-size: inherit !important;
  margin: inherit !important;
  padding: inherit !important;
}

/*
.mat-mdc-option {

} */
/*
.mat-mdc-select-arrow {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  background-color: #000000;
  clip-path: polygon(8% 17%, 0% 25%, 50% 84%, 100% 25%, 92% 17%, 50% 65%);
} */
.datepicker-icon {
  position: absolute !important;
  top: 2px !important;
  right: 0;
  color: var(--primary) !important;
  padding: 0.2rem !important;
  margin-right: 0.8rem !important;
  cursor: pointer;
}

button {
  /* min-width: 25px; */
  max-width: 300px;
}

/*
input {
  border: 1px solid var(--mdb-border-color);
  padding-left: 0.35rem;
}


/*
select {
	background-color: var(--lms-background);
	cursor: pointer;
	padding-right: 0.3rem;
}

option {
	font-weight: normal;
  font-size: 0.9rem;
	display: block;
	min-height: 1.4rem;
	padding: 0.2rem 0.1rem;
	white-space: nowrap;
}



.btn {
	text-transform: capitalize;
}

input[type="file"] {
	border: inherit;
	cursor: pointer;
}

.form-check-input {
	min-height: 1.4rem;
	min-width: 1.2rem;
	padding: 0.15em 0 0 0.07rem;
}
  */
.modal-body {
  background-color: rgba(229, 228, 255, 0.25);
}

.table-responsive {
  overflow-x: inherit;
}

.table {
  margin-top: .4rem;
  margin-left: auto;
  margin-right: auto;
}

/*
table > caption {
	background-color: var(--lms-element-background) !important;
	background-image: var(--mdb-gradient);
	color: var(--lms-element-text) !important;
	white-space: nowrap;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.table-center-header td,
.table-center-header th {
	color: var(--lms-element-text) !important;
	border-bottom: 1px solid var(--lms-element-background);
	background-color: var(--lms-element-background) !important;
	background-image: var(--mdb-gradient);
  vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
}

tbody tr {
	text-align: left;
} */

.field-4column-label tr td {
  /* min-height: 37px;
  height: 37px; */
  padding: .2rem .6rem;
  /* white-space: nowrap;
  vertical-align: middle; */
}

.field-4column-label tr td:nth-child(odd) {

  width: 25%;
  font-weight: 500;
}

.field-4column-label tr td:nth-child(even) {
  width: 27%;
  max-width: 27%;
}

.dialog-title {
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
}

.center-screen {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center-section {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.medium-list {
  width: 60vw;
  padding: 0 10px;
  margin: auto;
}

.small-list {
  width: 50vw;
  padding: 0 8px;
  margin: auto;
}

.list-viewer-wrapper {
  margin: 4px;
  border: 1px solid rgba(0, 0, 0, .03);
  box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
}

.list-header-title {
  padding: 8px 14px;
  /* color: rgba(0, 0, 0, .54); */
  color: var(--lms-text) !important;
  background: rgba(0, 0, 0, .03);
}

/*
.radio-group {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
} */
/*
.radio-button {
  margin: 2px;
} */

.sub-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.custom-tooltip {
  font-size: 20em;
}

.loan-type-container {
  width: 85vw;
  max-height: 520px;
  padding: .3em;
  margin-bottom: 5px;
}

.sub-panels-container {
  width: 85%;
  padding: 5px;
  margin-bottom: 5px;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 2px;
  display: block;
  background-color: var(--lms-panel-background);
}

.modal-header {
  align-items: flex-start;
}

.btn-close {
  padding: revert;
  margin: inherit;
}

.modal-title {
  align-items: flex-start;
  justify-content: center;
}

.modal-header .btn-close {
  align-self: flex-start;
  justify-self: flex-end;
}

.basic-panel-title {
  align-items: center;
  color: var(--lms-background);
  justify-content: center;
  margin: auto;
  text-align: center;
}

/*
.agent-landing-container {
	max-height: 800px;
	padding: .3em;
	background-color: var(--lms-panel-background);
}

.basic-panel-header {
	height: 33px;
	margin: 0;
  justify-content: center;
	background-color: var(--lms-element) !important;
	background-image: var(--mdb-gradient);
}



.error-label {
	flex-wrap: wrap;
	color: rgb(220, 53, 69);
}

.ng-valid[required],
.ng-valid.required {
  border-color: rgba(66, 169, 72, .7);
  border-left: 3px solid #42a948;
}

.ng-invalid:not(form) {
  border-color: #dc3545cc;
  border-left: 3px solid #a94442b4;
}
  */

.alert div {
  padding: 1rem;
  margin-bottom: 1rem;
  color: #820000;
  background-color: #fed3d3;
}

/*
.dataloader {
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
.toast-message a,
.toast-message label {
  color: var(--lms-text);
}

.toast-message a:hover {
  color: var(--lms-text);
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: var(--lms-text);
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.7;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: val(--lms-text);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
}


.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
} */


/* List styles */


mat-mdc-list-item:first-child {
  padding: 8px;
  background-color: #787dbd41;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-mdc-list-item {
  align-content: center;
  justify-content: space-between;
  min-height: 20px;
  padding-left: 0.4rem !important;
  padding-top: 0 !important;
  padding-right: 0.1rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

/* List header styles */

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */

/* Form styles */
mat-mdc-form-field {
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  width: 60%;
  margin-bottom: 8px;
}

/* mat-mdc-form-field {

  margin-bottom: 10px;
  /* flex-flow: row nowrap; */

/* justify-content: center;
align-content: center;
} */

.mat-mdc-icon-button {
  color: var(--lms-element-background);
  background-color: var(--lms-element-text);
}

.mat-mdc-list-item+mat-mdc-icon-button,
.mat-mdc-list-item+button {
  justify-items: flex-end;
}

/* Add button styles */
.mat-mdc-toolbar {
  position: fixed;
  right: 16px;
  bottom: 16px;
}

.mat-mdc-select,
input[mat-mdc-select] {
  border-radius: var(--mdb-border-radius-sm);
  margin-top: 0.05rem;
  height: 32px;
  min-width: 212px;
  padding-left: 0.3rem;

  padding-top: 0.2em;
  padding-right: 0.3rem;
  color: inherit;
  font-size: inherit;
}

/*
button .mdc-button--raised,
button[mat-button-raised],
button[mat-mdc-icon-button] {
	line-height: var(--lms-line-height);
	color: var(--lms-element-text);
	letter-spacing: normal;
	background-color: var(--lms-element);
	transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.aligndetails:host {
	display: block;
}

.aligndetails {
	vertical-align: top;
}

.btn-close,
.close.mat-button {
	position: relative;
	justify-content: end;
	height: 32px;
	padding: 5px;
	color: red;
}

.btn-close:hover,
.close.mat-button:hover {
	color: rgb(220, 53, 69);
	background-color: white;
}
*/
.example-sidenav {
  padding: 20px;
}

.example-sidenav-content {
  margin-top: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.mat-mdc-form-field {
  display: inherit;
  flex-direction: row;
  text-align: left;
}

/*
.mat-mdc-raised-button,
.mdc-raised-button {
	letter-spacing: normal;
	border-radius: 2px;
} */

.mat-datepicker-toggle-default-icon {
  align-items: center;
  vertical-align: middle !important;
  color: var(--primary);
}

.md-input-container {
  position: relative;
  display: inline-block;
  padding-top: 2px;
  padding-right: .2em;
  padding-bottom: 2px;
  padding-left: .2em;
  margin: .1em 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */
}

/*
.input:focus {
	background: #EEF8FF;
	border: 1px solid #5A7EC7;
	border-radius: 4px;
}

.input::placeholder {
	color: #787878;
}
  */

md-input-container .md-input,
.md-input,
.forminput {
  box-sizing: border-box;
  display: block;
  order: 2;
  float: left;
  width: 100%;
  height: var(--lms-input-height);
  padding: .2em .5em;
  margin: .25rem;
  line-height: var(--lms-line-height, 1.3);
  background: none;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--mdb-border-radius-sm);
  -ms-flex-order: 2;
  -ms-flex-preferred-size: 28px;
}


/* .forminput:required:valid {
  border-color: rgba(66, 169, 72, .75);
  ;
}

.forminput:invalid {
  border-color: rgba(220, 53, 69, 0.8);
} */

.form-control.ng-valid {
  margin-bottom: 0 !important;
}

.ng-valid:not([type="checkbox"]):not([type="radio"]):not(form):not(.not):not(:focus) {
  border-color: rgba(66, 169, 72, .8);
  border-left: 3px solid #42a948;
}

.ng-invalid:not([type="checkbox"]):not([type="radio"]):not(form):not(.not):not(:focus) {
  border-color: rgba(220, 53, 69, 0.8);
  border-left: 3px solid rgba(169, 68, 66, .8);
}

.forminput:required:focus:valid {
  background: 95% 50% rgba(144, 238, 144, 0.4);
}

.forminput:focus:invalid {
  background: 95% 50% lightsalmon;
}

/*
.ng-invalid:not([type="checkbox"]):not([type="radio"]):not(form):not(.not) {
  border-color: rgba(220, 53, 69, 0.8);
  border-left: 3px solid #a94442b4;
}

.ng-valid:not([type="checkbox"]):not([type="radio"]):not(form):not(.not) {
  border-color: rgba(66, 169, 72, .75);
  border-left: 3px solid #42a948;
} */

.mat-mdc-select {
  border-style: solid;
  border-width: 1px;
}

.mat-select-valid,
.mat-select:valid,
.mat-mdc-select-valid,
.mat-mdc-select:is(.md-input-valid) {
  border-color: rgba(66, 169, 72, .75);
  border-left: 3px solid #42a948;
}

.mat-select-invalid,
.mat-select:invalid,
.mat-mdc-select-invalid,
.mat-mdc-select:is(.md-input-invalid) {
  border-color: rgba(220, 53, 69, .8);
  border-left: 3px solid #a94442b4;
}

md-input-container {
  position: relative;
  display: inline-block;
  padding-top: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  margin: 1px 0;
  vertical-align: middle;
}

md-input-container .md-input {
  box-sizing: border-box;
  display: block;
  order: 2;
  float: left;
  width: 100%;
  height: var(--lms-input-height);
  ;
  padding-top: .4em;
  padding-bottom: .4em;

  border-style: solid;
  border-width: 0;
  background: none;
  border-left-width: 3px;
  border-radius: var(--mdb-border-radius-sm);
  -ms-flex-order: 2;
  -ms-flex-preferred-size: 29px;
}

md-input-container.md-input-focused .md-input,
md-input-container .md-input.ng-invalid.ng-dirty {
  padding-bottom: 0;
  border-width: 0 0 1px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: var(--mdb-danger);
}

button.btn-icon {
  color: var(--lms-element-background);
  background-color: var(--lms-element-text);
  position: relative;
  margin: 3px;
  border-radius: 50%;
}

button.btn-icon:hover {
  border-radius: 50%;
  color: var(--lms-element-text);
  background-color: var(--lms-element-background);
}


md-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: auto;
  vertical-align: middle;
  background-repeat: no-repeat no-repeat;
  fill: currentcolor;
}

md-icon svg {
  display: block;
  pointer-events: none;
}

md-icon[md-font-icon] {
  width: auto;
  line-height: 1;
}

md-input-container>md-icon {
  position: absolute;
  top: 5px;
  right: auto;
  left: 20px;
}
