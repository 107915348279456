// Variables

$enable-negative-margins: true !default;
$enable-gradients: false !default;

// Grays
$white: #fff !default;
$gray-50: #fbfbfb !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #4f4f4f !default;
$gray-900: #262626 !default;
$black: #000 !default;

// Material Design Color System - start
$blue-50: #e3f2fd !default;
$blue-100: #bbdefb !default;
$blue-200: #90caf9 !default;
$blue-300: #64b5f6 !default;
$blue-400: #42a5f5 !default;
$blue-500: #2196f3 !default;
$blue-600: #1e88e5 !default;
$blue-700: #1976d2 !default;
$blue-800: #1565c0 !default;
$blue-900: #0d47a1 !default;
$blue-accent-100: #82b1ff !default;
$blue-accent-200: #448aff !default;
$blue-accent-400: #2979ff !default;
$blue-accent-700: #2962ff !default;

$indigo-50: #e8eaf6 !default;
$indigo-100: #c5cae9 !default;
$indigo-200: #9fa8da !default;
$indigo-300: #7986cb !default;
$indigo-400: #5c6bc0 !default;
$indigo-500: #3f51b5 !default;
$indigo-600: #3949ab !default;
$indigo-700: #303f9f !default;
$indigo-800: #283593 !default;
$indigo-900: #1a237e !default;
$indigo-accent-100: #8c9eff !default;
$indigo-accent-200: #536dfe !default;
$indigo-accent-400: #3d5afe !default;
$indigo-accent-700: #304ffe !default;

$pink-50: #fce4ec !default;
$pink-100: #f8bbd0 !default;
$pink-200: #f48fb1 !default;
$pink-300: #f06292 !default;
$pink-400: #ec407a !default;
$pink-500: #e91e63 !default;
$pink-600: #d81b60 !default;
$pink-700: #c2185b !default;
$pink-800: #ad1457 !default;
$pink-900: #880e4f !default;
$pink-accent-100: #ff80ab !default;
$pink-accent-200: #ff4081 !default;
$pink-accent-400: #f50057 !default;
$pink-accent-700: #c51162 !default;

$red-50: #ffebee !default;
$red-100: #ffcdd2 !default;
$red-200: #ef9a9a !default;
$red-300: #e57373 !default;
$red-400: #ef5350 !default;
$red-500: #f44336 !default;
$red-600: #e53935 !default;
$red-700: #d32f2f !default;
$red-800: #c62828 !default;
$red-900: #b71c1c !default;
$red-accent-100: #ff8a80 !default;
$red-accent-200: #ff5252 !default;
$red-accent-400: #ff1744 !default;
$red-accent-700: #d50000 !default;

$yellow-50: #fffde7 !default;
$yellow-100: #fff9c4 !default;
$yellow-200: #fff59d !default;
$yellow-300: #fff176 !default;
$yellow-400: #ffee58 !default;
$yellow-500: #ffeb3b !default;
$yellow-600: #fdd835 !default;
$yellow-700: #fbc02d !default;
$yellow-800: #f9a825 !default;
$yellow-900: #f57f17 !default;
$yellow-accent-100: #ffff8d !default;
$yellow-accent-200: #ffff00 !default;
$yellow-accent-400: #ffea00 !default;
$yellow-accent-700: #ffd600 !default;

$green-50: #e8f5e9 !default;
$green-100: #c8e6c9 !default;
$green-200: #a5d6a7 !default;
$green-300: #81c784 !default;
$green-400: #66bb6a !default;
$green-500: #4caf50 !default;
$green-600: #43a047 !default;
$green-700: #388e3c !default;
$green-800: #2e7d32 !default;
$green-900: #1b5e20 !default;
$green-accent-100: #b9f6ca !default;
$green-accent-200: #69f0ae !default;
$green-accent-400: #00e676 !default;
$green-accent-700: #00c853 !default;

$teal-50: #e0f2f1 !default;
$teal-100: #b2dfdb !default;
$teal-200: #80cbc4 !default;
$teal-300: #4db6ac !default;
$teal-400: #26a69a !default;
$teal-500: #009688 !default;
$teal-600: #00897b !default;
$teal-700: #00796b !default;
$teal-800: #00695c !default;
$teal-900: #004d40 !default;
$teal-accent-100: #a7ffeb !default;
$teal-accent-200: #64ffda !default;
$teal-accent-400: #1de9b6 !default;
$teal-accent-700: #00bfa5 !default;

// $cyan-50: #e0f7fa !default;
// $cyan-100: #b2ebf2 !default;
// $cyan-200: #80deea !default;
// $cyan-300: #4dd0e1 !default;
// $cyan-400: #26c6da !default;
// $cyan-500: #00bcd4 !default;
// $cyan-600: #00acc1 !default;
// $cyan-700: #0097a7 !default;
// $cyan-800: #00838f !default;
// $cyan-900: #006064 !default;
// $cyan-accent-100: #84ffff !default;
// $cyan-accent-200: #18ffff !default;
// $cyan-accent-400: #00e5ff !default;
// $cyan-accent-700: #00b8d4 !default;

$blue-gray-50: #eceff1 !default;
$blue-gray-100: #cfd8dc !default;
$blue-gray-200: #b0bec5 !default;
$blue-gray-300: #90a4ae !default;
$blue-gray-400: #78909c !default;
$blue-gray-500: #607d8b !default;
$blue-gray-600: #546e7a !default;
$blue-gray-700: #455a64 !default;
$blue-gray-800: #37474f !default;
$blue-gray-900: #263238 !default;
// Material Design Color System - end

// Theme colors
$primary: #3f51b6 !default;
$secondary: #42b1bd !default;
$success: #51b63f !default;
$accent: #ff4081 !default;
$info: #3f8cb6 !default;
$warning: #b6a43f !default;
$danger: #b63f51 !default;
$light: #f1f1f1 !default;
$dark: #0d1024 !default;
$generic: #3eb283 !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "accent": $accent,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end theme-colors-map

// scss-docs-start ripple-colors-map
$ripple: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "accent": $accent,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end ripple-colors-map

// scss-docs-start buttons-colors-map
$buttons: (
  ) !default;
$buttons: map-merge(("primary": ("background-color": $primary,
        "text-color": $white,
        "shadow-color": $primary ),
      "secondary": ("background-color": $secondary,
        "text-color": $primary,
        "shadow-color": $secondary ),
      "accent": ("background-color": $accent,
        "text-color": $white,
        "shadow-color": $accent ),
      "success": ("background-color": $success,
        "text-color": $white,
        "shadow-color": $success ),
      "danger": ("background-color": $danger,
        "text-color": $white,
        "shadow-color": $danger ),
      "warning": ("background-color": $warning,
        "text-color": $white,
        "shadow-color": $warning ),
      "info": ("background-color": $info,
        "text-color": $white,
        "shadow-color": $info ),
      "light": ("background-color": $light,
        "text-color": #4f4f4f,
        "shadow-color": $light ),
      "dark": ("background-color": $dark,
        "text-color": $white,
        "shadow-color": $dark )),
    $buttons
);
// scss-docs-end buttons-colors-map

// scss-docs-start buttons-outline-colors-map
$buttons-outline: (
  ) !default;
$buttons-outline: map-merge(("primary": ("text-color": $primary,
        "border-color": $primary,
        "background-color": $primary ),
      "secondary": ("text-color": $secondary,
        "border-color": $secondary,
        "background-color": $secondary ),
      "accent": ("text-color": $accent,
        "border-color": $accent,
        "background-color": $accent ),
      "success": ("text-color": $success,
        "border-color": $success,
        "background-color": $success ),
      "danger": ("text-color": $danger,
        "border-color": $danger,
        "background-color": $danger ),
      "warning": ("text-color": $warning,
        "border-color": $warning,
        "background-color": $warning ),
      "info": ("text-color": $info,
        "border-color": $info,
        "background-color": $info ),
      "light": ("text-color": $light,
        "border-color": $light,
        "background-color": $light ),
      "dark": ("text-color": $dark,
        "border-color": $dark,
        "background-color": $dark )),
    $buttons-outline
);
// scss-docs-end buttons-outline-colors-map

// scss-docs-start note-colors-map
$note: (
  ) !default;
$note: map-merge(("primary": ("background-color": rgb(223, 231, 246),
        "border-color": rgb(63, 81, 181) // 3b71ca rgb(63, 81, 181)
      ),
      "secondary": ("background-color": rgb(235, 237, 239),
        "border-color": rgb(38, 134, 166)),
      "success": ("background-color": rgb(214, 240, 224),
        "border-color": rgb(19, 150, 71)),
      "accent": ("background-color": rgb(255, 233, 241),
        "border-color": rgb(255, 64, 128)),
      "danger": ("background-color": rgb(249, 225, 229),
        "border-color": rgb(214, 46, 74)),
      "warning": ("background-color": rgb(251, 240, 218),
        "border-color": rgb(161, 113, 18)),
      "info": ("background-color": rgb(222, 241, 247),
        "border-color": rgb(38, 134, 166)),
      "light": ("background-color": rgb(245, 245, 245),
        "border-color": rgb(140, 140, 140)),
      "dark": ("background-color": rgb(51, 46, 46),
        "border-color": rgb(232, 232, 232))),
    $note
);
// scss-docs-end note-colors-map

// scss-docs-start badges-colors-map
$badges: (
  ) !default;
$badges: map-merge(("primary": ("background-color": rgb(223, 231, 246),
        "text-color": rgb(44, 88, 160),
        "icon-color": rgb(63, 81, 181),
        "border-color": rgb(199, 214, 240)),
      "secondary": ("background-color": rgb(235, 237, 239),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(218, 222, 225)),
      "success": ("background-color": rgb(214, 240, 224),
        "text-color": rgb(13, 104, 50),
        "icon-color": rgb(19, 150, 71),
        "border-color": rgb(192, 231, 208)),
      "danger": ("background-color": rgb(249, 225, 229),
        "text-color": rgb(175, 35, 58),
        "icon-color": rgb(214, 46, 74),
        "border-color": rgb(244, 200, 207)),
      "warning": ("background-color": rgb(251, 240, 218),
        "text-color": rgb(115, 81, 13),
        "icon-color": rgb(161, 113, 18),
        "border-color": rgb(249, 228, 190)),
      "info": ("background-color": rgb(222, 241, 247),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(198, 230, 241)),
      "light": ("background-color": rgb(245, 245, 245),
        "text-color": rgb(79, 79, 79),
        "icon-color": rgb(140, 140, 140),
        "border-color": rgb(230, 230, 230)),
      "dark": ("background-color": rgb(51, 46, 46),
        "text-color": rgb(245, 245, 245),
        "icon-color": rgb(232, 232, 232),
        "border-color": rgb(68, 60, 60))),
    $badges
);
// scss-docs-end badges-colors-map

// scss-docs-start alerts-colors-map
$alerts: (
  ) !default;
$alerts: map-merge(("primary": ("background-color": rgb(223, 231, 246),
        "text-color": rgb(44, 88, 160),
        "icon-color": rgb(63, 81, 181),
        "border-color": rgb(199, 214, 240)),
      "secondary": ("background-color": rgb(235, 237, 239),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(218, 222, 225)),
      "success": ("background-color": rgb(214, 240, 224),
        "text-color": rgb(13, 104, 50),
        "icon-color": rgb(19, 150, 71),
        "border-color": rgb(192, 231, 208)),
      "danger": ("background-color": rgb(249, 225, 229),
        "text-color": rgb(175, 35, 58),
        "icon-color": rgb(214, 46, 74),
        "border-color": rgb(244, 200, 207)),
      "warning": ("background-color": rgb(251, 240, 218),
        "text-color": rgb(115, 81, 13),
        "icon-color": rgb(161, 113, 18),
        "border-color": rgb(249, 228, 190)),
      "info": ("background-color": rgb(222, 241, 247),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(198, 230, 241)),
      "light": ("background-color": rgb(245, 245, 245),
        "text-color": rgb(79, 79, 79),
        "icon-color": rgb(140, 140, 140),
        "border-color": rgb(230, 230, 230)),
      "dark": ("background-color": rgb(51, 46, 46),
        "text-color": rgb(245, 245, 245),
        "icon-color": rgb(232, 232, 232),
        "border-color": rgb(68, 60, 60))),
    $alerts
);
// scss-docs-end alerts-colors-map

// scss-docs-start toasts-colors-map
$toasts: (
  ) !default;
$toasts: map-merge(("primary": ("background-color": rgb(223, 231, 246),
        "text-color": rgb(44, 88, 160),
        "icon-color": rgb(63, 81, 181),
        "border-color": rgb(199, 214, 240)),
      "secondary": ("background-color": rgb(235, 237, 239),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(218, 222, 225)),
      "success": ("background-color": rgb(214, 240, 224),
        "text-color": rgb(13, 104, 50),
        "icon-color": rgb(19, 150, 71),
        "border-color": rgb(192, 231, 208)),
      "danger": ("background-color": rgb(249, 225, 229),
        "text-color": rgb(175, 35, 58),
        "icon-color": rgb(214, 46, 74),
        "border-color": rgb(244, 200, 207)),
      "warning": ("background-color": rgb(251, 240, 218),
        "text-color": rgb(115, 81, 13),
        "icon-color": rgb(161, 113, 18),
        "border-color": rgb(249, 228, 190)),
      "info": ("background-color": rgb(222, 241, 247),
        "text-color": rgb(28, 101, 125),
        "icon-color": rgb(38, 134, 166),
        "border-color": rgb(198, 230, 241)),
      "light": ("background-color": rgb(245, 245, 245),
        "text-color": rgb(79, 79, 79),
        "icon-color": rgb(140, 140, 140),
        "border-color": rgb(230, 230, 230)),
      "dark": ("background-color": rgb(51, 46, 46),
        "text-color": rgb(245, 245, 245),
        "icon-color": rgb(232, 232, 232),
        "border-color": rgb(68, 60, 60))),
    $toasts
);
// scss-docs-end toasts-colors-map

// Prefix for :root CSS variables

$variable-prefix: mdb- !default;

// Body
//
// Settings for the `<body>` element.

$body-color: $gray-700 !default;

$border-radius-xl: 0.5rem !default;

// Components

// Placeholders
$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;
//
//
// Shadows offset, blur, spread and color

$box-shadow-0: none !default;

$box-shadow-1: 0 0px 2px 0 rgb(0 0 0 / 7%),
  0 1px 1px 0 rgb(0 0 0 / 4%) !default;
$box-shadow-2: 0 0px 3px 0 rgb(0 0 0 / 7%),
  0 2px 2px 0 rgb(0 0 0 / 4%) !default;
$box-shadow-3: 0 2px 6px -1px rgb(0 0 0 / 7%),
  0 6px 18px -1px rgb(0 0 0 / 4%) !default;
$box-shadow-4: 0 2px 15px -3px rgb(0 0 0 / 7%),
  0 10px 20px -2px rgb(0 0 0 / 4%) !default;
$box-shadow-5: 0 2px 25px -5px rgb(0 0 0 / 7%),
  0 25px 21px -5px rgb(0 0 0 / 4%) !default;
$box-shadow-6: 0 2px 35px -12px rgb(0 0 0 / 21%),
  0 50px 40px -5px rgb(0 0 0 / 4%) !default;

$box-shadow-1-soft: 0 1px 5px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-2-soft: 0 2px 10px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-3-soft: 0 5px 15px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-4-soft: 0 10px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-5-soft: 0 15px 30px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-6-soft: 0 20px 40px 0 rgba(0, 0, 0, 0.05) !default;

$box-shadow-1-strong: 0 0px 2px 0 rgb(0 0 0 / 16%),
  0 1px 1px 0 rgb(0 0 0 / 10%) !default;
$box-shadow-2-strong: 0 0px 3px 0 rgb(0 0 0 / 16%),
  0 2px 2px 0 rgb(0 0 0 / 10%) !default;
$box-shadow-3-strong: 0 2px 6px -1px rgb(0 0 0 / 16%),
  0 6px 18px -1px rgb(0 0 0 / 10%) !default;
$box-shadow-4-strong: 0 2px 15px -3px rgb(0 0 0 / 16%),
  0 10px 20px -2px rgb(0 0 0 / 10%) !default;
$box-shadow-5-strong: 0 2px 25px -5px rgb(0 0 0 / 16%),
  0 25px 21px -5px rgb(0 0 0 / 10%) !default;
$box-shadow-6-strong: 0 2px 35px -12px rgb(0 0 0 / 26%),
  0 50px 40px -5px rgb(0 0 0 / 10%) !default;

$box-shadow-3-top: 0 -10px 20px 0 rgba(0, 0, 0, 0.05) !default;
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !default;

// scss-docs-start light-shadows-values
$shadows: (
  "0": $box-shadow-0,
  "1": $box-shadow-1,
  "2": $box-shadow-2,
  "3": $box-shadow-3,
  "4": $box-shadow-4,
  "5": $box-shadow-5,
  "6": $box-shadow-6) !default;
// scss-docs-end light-shadows-values

// scss-docs-start light-shadows-values
$shadows-soft: (
  "1": $box-shadow-1-soft,
  "2": $box-shadow-2-soft,
  "3": $box-shadow-3-soft,
  "4": $box-shadow-4-soft,
  "5": $box-shadow-5-soft,
  "6": $box-shadow-6-soft) !default;
// scss-docs-end light-shadows-values

// scss-docs-start strong-shadows-values
$shadows-strong: (
  "1": $box-shadow-1-strong,
  "2": $box-shadow-2-strong,
  "3": $box-shadow-3-strong,
  "4": $box-shadow-4-strong,
  "5": $box-shadow-5-strong,
  "6": $box-shadow-6-strong) !default;
// scss-docs-end strong-shadows-values

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-roboto: "Roboto",
  sans-serif !default;
$font-family-base: var(--mdb-font-roboto) !default;

$line-height-base: 1.4 !default;
// Buttons
//
// For each of MDB's buttons, define text, background, and border color.

$btn-line-height: 1.4 !default;
$btn-padding-top: 0.55rem !default;
$btn-padding-bottom: 0.55rem !default;
$btn-font-size: 0.875rem !default;
$btn-padding-left: 1.5rem !default;
$btn-padding-right: 1.5rem !default;
$btn-font-weight: 400 !default;
$btn-box-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15),
  0 4px 18px 0 rgba(0, 0, 0, 0.1) !default;
$btn-hover-box-shadow: 0 8px 9px -4px rgba(63, 81, 182, 0.3),
  0 4px 18px 0 rgba(63, 81, 182, 0.2) !default;
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-active-box-shadow: $btn-hover-box-shadow !default;
$btn-border-width: 0.125rem !default;

$btn-outline-padding-top: 0.5rem !default;
$btn-outline-padding-bottom: 0.375rem !default;
$btn-outline-padding-left: 1.375rem !default;
$btn-outline-padding-right: 1.375rem !default;

$btn-outline-padding-top-sm: 0.25rem !default;
$btn-outline-padding-bottom-sm: 0.1875rem !default;
$btn-outline-padding-right-sm: 0.875rem !default;
$btn-outline-padding-left-sm: 0.875rem !default;

$btn-outline-padding-top-lg: 0.625rem !default;
$btn-outline-padding-bottom-lg: 0.5625rem !default;
$btn-outline-padding-right-lg: 1.5625rem !default;
$btn-outline-padding-left-lg: 1.5625rem !default;

$btn-line-height-lg: 1.7 !default;
$btn-font-size-lg: 0.9rem !default;
$btn-padding-top-lg: 0.75rem !default;
$btn-padding-bottom-lg: 0.6875rem !default;
$btn-padding-right-lg: 1.6875rem !default;
$btn-padding-left-lg: 1.6875rem !default;

$btn-line-height-sm: $btn-line-height !default;
$btn-font-size-sm: 0.75rem !default;
$btn-padding-top-sm: 0.375rem !default;
$btn-padding-bottom-sm: 0.3125rem !default;
$btn-padding-right-sm: 1rem !default;
$btn-padding-left-sm: 1rem !default;

$link-color: $primary !default;
$link-decoration: none !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$btn-link-bgc: #f5f5f5 !default;
$btn-rounded-border-radius: 10rem !default;

$btn-floating-border-radius: 50% !default;

$btn-floating-width: 2.3125rem !default;
$btn-floating-height: 1.825rem !default;
$btn-floating-icon-width: 2.3125rem !default;
$btn-floating-icon-line-height: 1.3125rem !default;

$btn-floating-width-lg: 2.8125rem !default;
$btn-floating-height-lg: 1.8125rem !default;
$btn-floating-icon-width-lg: 2.8125rem !default;
$btn-floating-icon-line-height-lg: 1.8825rem !default;

$btn-floating-width-sm: 1.8125rem !default;
$btn-floating-height-sm: 1.4125rem !default;
$btn-floating-icon-width-sm: 1.8125rem !default;
$btn-floating-icon-line-height-sm: 1.5125rem !default;

$btn-outline-floating-icon-width: 2.0625rem !default;
$btn-outline-floating-icon-line-height: 2.0625rem !default;

$btn-outline-floating-icon-width-lg: 2.5625rem !default;
$btn-outline-floating-icon-line-height-lg: 2.5625rem !default;

$btn-outline-floating-icon-width-sm: 1.5625rem !default;
$btn-outline-floating-icon-line-height-sm: 1.5625rem !default;

$btn-floating-border-radius: 50% !default;

$btn-block-spacing-y: 0.85rem !default;

$fixed-action-btn-right: 2.1875rem !default;
$fixed-action-btn-bottom: 2.1875rem !default;
$fixed-action-btn-padding-top: 0.9375rem !default;
$fixed-action-btn-li-margin-bottom: 1.5rem !default;

$btn-contextual-box-shadow: 0 4px 9px -4px !default;
$btn-contextual-box-shadow-state-first-part: 0 8px 9px -4px !default;
$btn-contextual-box-shadow-state-second-part: 0 4px 18px 0 !default;
$btn-secondary-hover-focus-bgc: shade-color(#e3ebf7, 3%) !default;
$btn-secondary-active-bgc: shade-color(#e3ebf7, 6%) !default;
$btn-pink-hover-focus-bgc: shade-color(#e3ebf7, 3%) !default;
$btn-pink-active-bgc: shade-color(#fce4ec, 6%) !default;
$btn-accent-hover-focus-bgc: shade-color(#e3ebf7, 3%) !default;
$btn-accent-active-bgc: shade-color(#fce4ec, 6%) !default;
// Button group
//
// Material styling for group of buttons

$btn-group-border-radius: calc(4px - 1px) !default;
$btn-group-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Forms
//
// Material styling for outline forms border, label, size

$input-padding-top: 0.33em !default;
$input-padding-bottom: $input-padding-top !default;
$input-padding-left: 0.33em !default;
$input-padding-right: $input-padding-left !default;
$input-transition: all 0.2s linear !default;

$form-label-left: 0.75rem !default;
$form-label-padding-top: 0.37rem !default;
$form-label-transition: all 0.2s ease-out !default;
$form-label-color: rgba(0, 0, 0, 0.6) !default;

$input-focus-active-label-transform: translateY(-1rem) translateY(0.1rem) scale(0.7) !default;
$input-focus-label-color: $primary !default;
$input-focus-border-width: 0.25rem !default;
$input-focus-border-color: $primary !default;
$input-disabled-background-color: #e9ecef !default;

$input-padding-left-lg: 0.75em !default;
$input-padding-right-lg: $input-padding-left-lg !default;
$input-font-size-lg: 1rem !default;
$input-line-height-lg: 2.15 !default;
$input-focus-active-label-transform-lg: translateY(-1.25rem) translateY(0.1rem) scale(0.7) !default;

$input-padding-left-sm: 0.99em !default;
$input-padding-right-sm: $input-padding-left-sm !default;
$input-padding-top-sm: 0.43em !default;
$input-padding-bottom-sm: 0.35em !default;
$input-font-size-sm: 0.775rem !default;
$input-line-height-sm: 1.3 !default;
$input-focus-active-label-transform-sm: translateY(-0.85rem) translateY(0.1rem) scale(0.7) !default;

$form-notch-div-border-color: #bdbdbd !default;
$form-notch-leading-width: 0.5rem !default;
$form-notch-leading-border-radius: 0.25rem !default;
$form-notch-middle-max-width: 1rem !default;
$form-notch-trailing-border-radius: $form-notch-leading-border-radius !default;

$form-label-padding-top-lg: 0.7rem !default;
$form-label-padding-top-sm: 0.33rem !default;
$form-label-font-size-sm: 0.775rem !default;

$form-white-input-color: $white !default;
$form-white-label-color: $gray-50 !default;
$form-white-notch-div-border-color: $gray-50 !default;
$form-white-input-focus-label-color: $white !default;
$form-white-input-focus-border-color: $white !default;
$form-white-placeholder-color: rgba(255, 255, 255, 0.7) !default;
$form-white-disabled-bgc: rgba(255, 255, 255, 0.45) !default;

$form-check-input-margin-left: 1.79rem !default;
$form-check-input-radio-margin-left: 1.85rem !default;
$form-check-input-width-md: 1.125rem !default;
$form-check-input-height: $form-check-input-width-md !default;
$form-check-input-background-color: transparent !default;
$form-check-input-border-width: 0.12rem !default;
$form-check-input-border-color: rgba(0, 0, 0, 0.25) !default;

$form-check-input-before-box-shadow: 0px 0px 0px 13px transparent !default;
$form-check-input-before-border-radius: 50% !default;
$form-check-input-before-width: 0.9rem !default;
$form-check-input-before-height: $form-check-input-before-width !default;
$form-check-input-before-transform: scale(0) !default;

$form-check-input-hover-before-opacity: 0.04 !default;
$form-check-input-hover-before-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.6) !default;

$form-check-input-focus-border-color: $form-check-input-border-color !default;
$form-check-input-focus-transition: border-color 0.2s !default;

$form-check-input-focus-before-opacity: 0.12 !default;
$form-check-input-focus-before-box-shadow: $form-check-input-hover-before-box-shadow !default;
$form-check-input-focus-before-transform: scale(1) !default;
$form-check-input-focus-before-transition: box-shadow 0.2s,
  transform 0.2s !default;

$form-check-input-checked-border-color: $primary !default;
$form-check-input-checked-before-opacity: 0.16 !default;
$form-check-input-checked-focus-border-color: $primary !default;
$form-check-input-checked-focus-before-box-shadow: 0px 0px 0px 10px $primary !default;
$form-check-input-checked-focus-before-transform: $form-check-input-focus-before-transform !default;
$form-check-input-checked-focus-before-transition: $form-check-input-focus-before-transition !default;

$form-check-input-checkbox-border-radius: 0.25rem !default;
$form-check-input-checkbox-focus-after-width: 0.875rem !default;
$form-check-input-checkbox-focus-after-height: $form-check-input-checkbox-focus-after-width !default;
$form-check-input-checkbox-focus-after-background-color: $white !default;
$form-check-input-checkbox-checked-background-color: $primary !default;
$form-check-input-checkbox-checked-focus-background-color: $primary !default;

$form-check-input-checkbox-checked-after-transform: rotate(45deg) !default;
$form-check-input-checkbox-checked-after-border-width: 0.125rem !default;
$form-check-input-checkbox-checked-after-border-color: $white !default;
$form-check-input-checkbox-checked-after-width: 0.375rem !default;
$form-check-input-checkbox-checked-after-height: 0.8125rem !default;
$form-check-input-checkbox-checked-after-margin-left: 0.25rem !default;
$form-check-input-checkbox-checked-after-margin-top: -1px !default;

$form-check-input-indeterminate-focus-before-box-shadow: 0px 0px 0px 13px $primary !default;
$form-check-input-indeterminate-checked-background-color: $primary !default;
$form-check-input-indeterminate-checked-after-transform: rotate(90deg) !default;
$form-check-input-indeterminate-checked-after-border-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-border-color: $white !default;
$form-check-input-indeterminate-checked-after-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-height: 0.875rem !default;
$form-check-input-indeterminate-checked-after-margin-left: 0.375rem !default;
$form-check-input-indeterminate-focus-background-color: $primary !default;
$form-check-input-indeterminate-focus-border-color: $primary !default;
$form-check-input-indeterminate-border-color: $gray-600 !default;

$form-check-input-radio-border-radius: 50% !default;
$form-check-input-radio-width: 1rem !default;
$form-check-input-radio-height: $form-check-input-radio-width !default;

$form-check-input-radio-before-width: 1rem !default;
$form-check-input-radio-before-height: $form-check-input-radio-before-width !default;

$form-check-input-radio-after-width: $form-check-input-radio-before-width !default;
$form-check-input-radio-after-height: $form-check-input-radio-after-width !default;
$form-check-input-radio-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-after-background-color: transparent !default;

$form-check-input-radio-checked-background-color: transparent !default;
$form-check-input-radio-checked-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-checked-after-width: 0.625rem !default;
$form-check-input-radio-checked-after-height: $form-check-input-radio-checked-after-width !default;
$form-check-input-radio-checked-after-border-color: $primary !default;
$form-check-input-radio-checked-after-background-color: $primary !default;
$form-check-input-radio-checked-after-margin-top: 0.1875rem !default;
$form-check-input-radio-checked-after-margin-left: 0.1875rem !default;
$form-check-input-radio-checked-after-transition: border-color !default;
$form-check-input-radio-checked-focus-background-color: transparent !default;

$form-switch-padding-left: 1.85rem !default;
$form-switch-form-check-input-border-radius: 0.4375rem !default;
$form-switch-form-check-input-width: 2rem !default;
$form-switch-form-check-input-height: 0.875rem !default;
$form-switch-form-check-input-background-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-form-check-input-margin-top: 0.35rem !default;
$form-switch-form-check-input-margin-right: 0.7rem !default;

$form-switch-form-check-input-after-border-radius: 40% !default;
$form-switch-form-check-input-after-width: 1.25rem !default;
$form-switch-form-check-input-after-height: $form-switch-form-check-input-after-width !default;
$form-switch-form-check-input-after-background-color: $white !default;
$form-switch-form-check-input-after-margin-top: -0.1875rem !default;
$form-switch-form-check-input-after-box-shadow: $box-shadow-2 !default;
$form-switch-form-check-input-after-transition: background-color 0.2s,
  transform 0.2s !default;

$form-switch-form-check-input-focus-before-box-shadow: 3px -1px 0px 10px rgba(0, 0, 0, 0.6) !default;
$form-switch-form-check-input-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-focus-before-transition: box-shadow 0.2s,
  transform 0.2s !default;

$form-switch-form-check-input-focus-after-border-radius: $form-switch-form-check-input-after-border-radius !default;
$form-switch-form-check-input-focus-after-width: 1.25rem !default;
$form-switch-form-check-input-focus-after-height: $form-switch-form-check-input-focus-after-width !default;

$form-switch-form-check-input-checked-focus-before-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-focus-before-box-shadow: 3px -1px 0px 10px $primary !default;
$form-switch-form-check-input-checked-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-checked-focus-before-transition: box-shadow 0.2s,
  transform 0.2s !default;

$form-switch-form-check-input-checked-checkbox-after-border-radius: 40% !default;
$form-switch-form-check-input-checked-checkbox-after-width: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-height: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-background-color: $primary !default;
$form-switch-form-check-input-checked-checkbox-after-margin-top: -3px !default;
$form-switch-form-check-input-checked-checkbox-after-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-checkbox-after-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$form-switch-form-check-input-checked-checkbox-after-transition: background-color 0.2s,
  transform 0.2s !default;

$form-file-height: calc(1.79rem + 0.33rem + 2px) !default;

$form-file-label-focus-border-color: $primary !default;
$form-file-label-focus-transition: all 0.2s linear !default;
$form-file-label-focus-text-border-width: 2px 1px 2px 2px !default;
$form-file-label-focus-button-border-width: 2px 2px 2px 1px !default;
$form-file-label-disabled-background-color: #e9ecef !default;
$form-file-label-height: $form-file-height !default;
$form-file-label-border-color: #bdbdbd !default;
$form-file-label-border-radius: 0.2rem !default;

$form-file-text-padding-y: 0.33rem !default;
$form-file-text-border-color: #bdbdbd !default;

$form-file-button-line-height: 1.5 !default;
$form-file-button-background-color: #fff !default;
$form-file-button-border-color: $form-file-text-border-color !default;

$form-file-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$form-file-sm-font-size: 0.775rem !default;
$form-file-sm-line-height: 1.5 !default;
$form-file-sm-padding-y: $form-file-text-padding-y !default;

$form-file-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$form-file-lg-font-size: 1rem !default;
$form-file-lg-line-height: 2.15 !default;
$form-file-lg-padding-y: $form-file-text-padding-y !default;

$form-range-webkit-slider-thumb-margin-top: -6px !default;
$form-range-webkit-slider-runnable-track-height: 4px !default;

$input-group-height: calc(1.7rem + 0.33rem) !default;
$input-group-min-height: $input-group-height !default;
$input-group-padding-y: 0.25rem !default;
$input-group-transition: all 0.2s linear !default;

$input-group-focus-transition: $input-group-transition !default;
$input-group-focus-border-color: $primary !default;
$input-group-focus-box-shadow: inset 0 0 0 1px $primary !default;

$input-group-text-padding-y: 0.23rem !default;

$input-group-lg-height: calc(2.315rem + 0.33rem) !default;
$input-group-lg-font-size: 1rem !default;
$input-group-lg-padding-y: 0.31rem !default;
$input-group-lg-text-font-size: $input-group-lg-font-size !default;

$input-group-sm-height: calc(1.33rem + 0.33rem) !default;
$input-group-sm-min-height: $input-group-sm-height !default;
$input-group-sm-font-size: 0.775rem !default;
$input-group-sm-padding-y: $input-group-lg-padding-y !default;
$input-group-sm-text-font-size: $input-group-sm-font-size !default;
$input-group-sm-text-line-height: 1.5 !default;

$input-group-form-outline-border-left-width: 1px !default;
$input-group-form-outline-border-left-color: #bdbdbd !default;

// Form validation

$form-feedback-valid-tooltip-color: $white !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

// scss-docs-start form-validation-states
$form-validation-states-mdb: (
  "valid": ("color": $form-feedback-valid-color ),
  "invalid": ("color": $form-feedback-invalid-color )) !default;
// scss-docs-end form-validation-states

// scss-docs-start zindex-stack
$zindex-fixed-action-button: 1030 !default;
$zindex-toast: 1060 !default;
$zindex-alert: 1070 !default;
$zindex-popover: 1080 !default;
$zindex-tooltip: 1090 !default;
// scss-docs-end zindex-stack

// Navs

$nav-tabs-link-border-width: 0 0 2px 0 !default;
$nav-tabs-link-font-weight: 500 !default;
$nav-tabs-link-font-size: 12px !default;
$nav-tabs-link-padding-top: 17px !default;
$nav-tabs-link-padding-bottom: 16px !default;
$nav-tabs-link-padding-x: 29px !default;
$nav-tabs-link-hover-background-color: rgba(247, 247, 247, 0.2) !default;

$nav-pills-margin: 0.5rem !default;
$nav-pills-link-border-radius: 0.25rem !default;
$nav-pills-link-font-size: $nav-tabs-link-font-size !default;
$nav-pills-link-padding-top: $nav-tabs-link-padding-top !default;
$nav-pills-link-padding-bottom: $nav-tabs-link-padding-bottom !default;
$nav-pills-link-padding-x: $nav-tabs-link-padding-x !default;
$nav-pills-link-line-height: 1 !default;
$nav-pills-link-background-color: $nav-tabs-link-hover-background-color !default;
$nav-pills-link-font-weight: $nav-tabs-link-font-weight !default;

$nav-tabs-link-color: rgba(0, 0, 0, 0.55) !default;
$nav-tabs-link-hover-bgc: rgba(247, 247, 247, 0.2) !default;

$nav-pills-link-color: $nav-tabs-link-color !default;
$nav-pills-link-hover-bgc: $nav-tabs-link-hover-bgc !default;
$nav-pills-link-active-bgc: #e3ebf7 !default;
$nav-pills-link-active-color: #285192 !default;

// Navbar

$navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.05) !default;
$navbar-padding-top: 0.5625rem !default;
$navbar-brand-img-margin-right: 0.25rem !default;

// Dropdowns
//
// Dropdown menu container and content !default;

$dropdown-color: $body-color !default;
$dropdown-margin-top: 0.12rem !default;
$dropdown-box-shadow: $box-shadow-4 !default;
$dropdown-font-size: 0.875rem !default;
$dropdown-item-border-radius: 0.4rem !default;
$dropdown-item-padding-y: 0.45rem !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-border-radius: 0.4rem !default;
$dropdown-divider-bg: hsl(0, 0%, 96%) !default;

$dropdown-state-color: #16181b !default;
$dropdown-state-background-color: #eee !default;

$dropdown-menu-animated-animation-duration: 0.55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

// Pagination

$pagination-font-size: 0.9rem !default;
$pagination-color: $body-color !default;
$pagination-transition: all 0.3s linear !default;
$pagination-border-radius: 0.25rem !default;

$pagination-hover-color: $pagination-color !default;
$pagination-active-box-shadow: $btn-box-shadow !default;
$pagination-active-transition: all 0.2s linear !default;

$pagination-circle-border-radius: 50% !default;
$pagination-circle-padding-x: 0.841rem !default;

$pagination-circle-lg-padding-left: 1.399414rem !default;
$pagination-circle-lg-padding-right: 1.399415rem !default;

$pagination-circle-sm-padding-left: 0.696rem !default;
$pagination-circle-sm-padding-right: 0.688rem !default;

$page-item-active-link-bgc: #e3ebf7 !default;
$page-item-active-link-color: #285192 !default;
$page-item-active-link-box-shadow: 0 4px 9px -4px #e3ebf7 !default;
$page-item-active-link-font-weight: 500 !default;

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.1rem !default;
$card-box-shadow: $box-shadow-4 !default;
$card-header-background-color: rgba(255, 255, 255, 0) !default;
$card-footer-background-color: rgba(255, 255, 255, 0) !default;
$card-header-border-width: 2px !default;
$card-header-border-color: hsl(0, 0%, 96%) !default;
$card-footer-border-width: 2px !default;
$card-footer-border-color: hsl(0, 0%, 96%) !default;
$card-border-radius: 0.5rem !default;
$card-img-overlay-padding: 1.5rem !default;

// Accordion

$accordion-padding-y: 1rem !default;
$accordion-padding-x: 0.8rem !default;
$accordion-button-active-bg: rgb(44, 88, 160) !default;
$accordion-button-active-color: rgb(223, 231, 246) !default;
$accordion-button-focus-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125) !default;
$accordion-border-radius: $border-radius-xl;
$accordion-flush-button-box-shadow: inset 0 -2px 0 #f5f5f5 !default;
$accordion-flush-item-border-bottom: 3px solid #f5f5f5 !default;
$accordion-borderless-button-border-radius: 0.3rem !default;
$accordion-borderless-button-bgc: rgb(227, 235, 247) !default;
$accordion-borderless-button-color: rgb(44, 88, 160) !default;

// Tooltips

$tooltip-inner-color: #fff !default;
$tooltip-inner-padding-y: 6px !default;
$tooltip-inner-padding-x: 16px !default;
$tooltip-inner-font-size: 14px !default;
$tooltip-inner-background-color: #6d6d6d !default;
$tooltip-inner-border-radius: 0.25rem !default;

// Popovers

$popover-box-shadow: $box-shadow-4 !default;
$popover-background-color: #fff !default;
$popover-border-radius: $border-radius-xl !default;
$popover-border-width: 2px !default;
$popover-border-color: hsl(0, 0%, 96%) !default;

// Toasts

$toast-background-color: #fff !default;
$toast-box-shadow: $box-shadow-4 !default;
$toast-header-background-color: #fff !default;
$toast-border-radius: $border-radius-xl !default;
$toast-padding-x: 1rem !default;
$toast-padding-y: 0.65rem !default;

// Badges

$badge-dot-border-radius: 4.5px !default;
$badge-dot-height: 9px !default;
$badge-dot-width: $badge-dot-height !default;
$badge-dot-margin-left: -0.3125rem !default;

$badge-notification-font-size: 0.6rem !default;
$badge-notification-margin-top: -0.1rem !default;
$badge-notification-margin-left: -0.5rem !default;
$badge-notification-padding-y: 0.2em !default;
$badge-notification-padding-x: 0.45em !default;

$badge-border-radius: 0.27rem !default;

// Modals

$modal-box-shadow: $box-shadow-4 !default;
$modal-content-border-radius: $border-radius-xl !default;
$modal-header-border-color: hsl(0, 0%, 96%) !default;
$modal-footer-border-color: hsl(0, 0%, 96%) !default;
$modal-header-border-width: 2px !default;
$modal-footer-border-width: 2px !default;

// Alerts

$alert-padding-y: 1.25rem !default;
$alert-padding-x: 1.5rem !default;
$alert-border-radius: $border-radius-xl !default;

// Progress bars

$progress-height: 4px !default;

// List group

$list-group-border-radius: $border-radius-xl !default;
$list-group-item-padding-x: 1.5rem !default;
$list-group-color: $body-color !default;

$list-group-light-item-py: 1rem !default;
$list-group-light-item-border: 2px solid hsl(0, 0%, 96%) !default;
$list-group-light-item-border-width: 2px !default;
$list-group-light-active-border-radius: 0.5rem !default;
$list-group-light-active-bgc: hsl(217, 88.2%, 90%) !default;
$list-group-light-active-color: hsl(217, 88.8%, 35.1%) !default;

$list-group-small-item-py: 0.5rem !default;

$list-group-item-active-bgc: rgb(223, 231, 246) !default;
$list-group-item-active-color: rgb(44, 88, 160) !default;
$list-group-item-active-border-color: $list-group-item-active-bgc !default;

// Carousel

$carousel-control-prev-icon-bg: none;
$carousel-control-next-icon-bg: none;

// Breadcrumbs

$breadcrumb-item-color: rgba(0, 0, 0, 0.55) !default;
$breadcrumb-item-transition: color 0.15s ease-in-out !default;
$breadcrumb-item-hover-color: rgba(0, 0, 0, 0.7) !default;
$breadcrumb-item-before-color: $breadcrumb-item-color !default;

// Images

$image-hover-overlay-transition: all 0.3s ease-in-out !default;

$image-hover-zoom-transition: all 0.3s linear !default;
$image-hover-zoom-transform: scale(1.1) !default;

$image-hover-shadow-transition: $image-hover-overlay-transition !default;
$image-hover-shadow-box-shadow: $box-shadow-4-strong !default;
$image-hover-shadow-box-shadow-soft: $box-shadow-5 !default;

// Scrollspy

$scrollspy-menu-sidebar-font-size: 0.8rem !default;
$scrollspy-menu-sidebar-color: #262626 !default;
$scrollspy-menu-sidebar-line-height: 1.1rem !default;
$scrollspy-menu-sidebar-padding-x: 5px !default;
$scrollspy-menu-sidebar-font-weight: 400 !default;
$scrollspy-menu-sidebar-transition: all 0.2s ease-in-out !default;
$scrollspy-menu-sidebar-margin-y: 3px !default;

$scrollspy-menu-sidebar-active-color: $primary !default;
$scrollspy-menu-sidebar-active-font-weight: 600 !default;
$scrollspy-menu-sidebar-active-border-width: 0.125rem !default;
$scrollspy-menu-sidebar-active-border-color: $primary !default;

// Type

$note-padding: 10px !default;
$note-border-width: 6px !default;
$note-border-radius: 5px !default;
$note-strong-font-weight: 600 !default;
$note-paragraph-font-weight: 500 !default;

// Table

$table-color: $body-color !default;
$table-font-size: 0.9rem !default;
$table-hover-transition: 0.5s !default;
$table-group-separator-color: inherit !default;
$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1.4rem !default;
$table-cell-padding-y-sm: 0.5rem !default;
$table-cell-padding-x-sm: $table-cell-padding-x !default;
$table-striped-bg: rgba(0, 0, 0, 0.02) !default;
$table-hover-bg: rgba(0, 0, 0, 0.025) !default;
$table-bg-scale: -80% !default;

// scss-docs-start table-loop
$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark) !default;
// scss-docs-end table-loop

// Select
$form-select-transition: all 0.3s linear !default;
$form-select-focus-border-color: $primary !default;
$form-select-focus-border-box-shadow: inset 0px 0px 0px 1px $primary !default;
