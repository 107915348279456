@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@import "assets/scss/mdb.scss";
@import "assets/toastr.css";

$lms_element_text: $component-active-color;
$lms_element_bg: $primary;
$lms_input_bg: $gray-100;
$lms-border-valid: #42a948b3;
$lms-border-invalid: #dc3545cc;
// $lms-placeholder: #787878;
$lms-line-height: $line-height-base;

$lms-element-text: $gray-50;
$lms-element-bg: $primary;
$lms_input_bg: white;
$lms-border-valid: #42a948b3;
$lms-border-invalid: #dc3545cc;
$lms-placeholder: $gray-100;
$lms-line-height: 1.3;
$lms-input-height: 32px;

$clms-client-primary: mat.define-palette(mat.$indigo-palette, 500, 100, 300);
$clms-client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$clms-client-warn: mat.define-palette(mat.$red-palette);

$custom-typography-config: mat.define-legacy-typography-config($font-family: $font-family-roboto,
    $headline: mat.define-typography-level(20px, 20px, 600),
    /*<h1>*/
    $title: mat.define-typography-level(16px, 16px, 600),
    /*<h2>**/
    $subheading-2: mat.define-typography-level($font-family: $font-family-roboto,
      $font-weight: 500,
      $font-size: 1rem,
      $line-height: 1.7,
      $letter-spacing: normal),
    /*<h3>*/
    $subheading-1: mat.define-typography-level($font-family: $font-family-roboto,
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5,
      $letter-spacing: normal),
    /*<h4>*/
    $display-4: mat.define-typography-level(14px, 14px, 300, $letter-spacing: -0.05em),
    /*No Native Element*/
    $display-3: mat.define-typography-level(14px, 14px, 400, $letter-spacing: -0.02em),
    /*No Native Element*/
    $body-1: mat.define-typography-level(14px, 30px, 400),
    /* Body Text */
    $caption: mat.define-typography-level(14px, 30px, 500),
    /*No Native Element*/
    $button: mat.define-typography-level(14px, 30px, 400),
    $display-2: mat.define-typography-level(15px, 15px, 400, $letter-spacing: -0.005em),
    /*No Native Element*/
    $display-1: mat.define-typography-level(14px, 14px, 400),
    /*No Native Element*/
    $body-2: mat.define-typography-level(12px, 14px, 400),
    /*No Native Element*/
    $input: mat.define-typography-level(14px, 1.2, 400));

// Define a light theme
$clms-client-theme: mat.define-light-theme((color: (primary: $clms-client-primary,
        accent: $clms-client-accent,
        warn: $clms-client-warn ),
      typography: $custom-typography-config ));

@include mat.legacy-core();
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography-config);`
// @include mat.all-component-typographies($custom-typography-config);
@include mat.legacy-typography-hierarchy($custom-typography-config);
// @include mat.core();

// Apply the light theme by default
@include mat.all-component-themes($clms-client-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.checkbox-density(-1);
@include mat.radio-density(-1);

@include mat.select-density(-1);
// @include mat.slide-toggle-density(-1);
@include mat.table-density(-1);

@mixin button-variant-mdb($lms-element-bg) {}

.center-screen {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  overflow: auto;
}

.datepickers .md-form,
.inputs .md-form {
  margin-top: 0 !important;
}

.form-control {
  line-height: 1.3 !important;
}

.table-responsive {
  overflow-x: inherit;
}

.mat-date-range-input-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
}

.mat-date-range-input-wrapper {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.mat-date-range-input-wrapper>input {
  flex-grow: 1;
  padding-top: 0.1rem !important;
  margin-top: 0.1rem !important;
  min-width: 100px;
  width: 100px;
}

input:active {
  border-color: $input-focus-border-color;
  transition: border-color 0.3s ease-out;
}

.form-check-input[type="radio"] {
  margin-right: inherit;
  width: 1.29rem;
  height: 1.28rem;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="date"]):not(.not),
optgroup,
.lmsselect,
textarea {
  color: inherit;
  font: inherit;
  // border-color: $input-border-color;
  // height: $lms-input-height;
  // line-height: $lms-line-height;
  width: 100%;
  //min-width: 210px;
  // padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
  // padding-left: 0.3rem !important;
  transition: border-color 0.3s ease-out;
}

a {
  &.btn {
    min-width: 20px;
    background-color: $lms-element-bg;
    color: $lms-element-text;
    // transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    // text-transform: capitalize;
  }
}

input[type="file"] {
  display: inline-block;
  width: 225px;
  border: inherit;
  cursor: pointer;
}

// .form-check-input {
//   padding: 0.18em 0.1em 0 0.07rem;
// }

// input[type="checkbox"] {
//   height: 20px;
//   width: 20px;
//   min-width: 20px !important;
//   padding-left: 0.07em;
//   transition: border-color 0.3s ease-out;
// }

// input[type="radio"] {
//   display: inline-flex;
//   width: 1.28rem;
//   height: 1.26rem;
//   transition: border-color 0.3s ease-out;
// }

.list-item:hover {
  background-color: rgba(195, 191, 224, 0.267);
}

.error-label {
  flex-wrap: wrap;
  color: rgb(220, 53, 69);
}

.table {
  overflow-x: inherit;

  &>tbody {
    tr {
      vertical-align: middle;
      text-align: left;

      td {
        min-height: 37px !important;
        height: 37px;
      }
    }
  }

  >caption {
    background-color: $lms-element-bg !important;
    color: $lms-element-text !important;
    font-weight: 400 !important;
    white-space: nowrap;
    text-align: center;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
  }
}

.table[w350] {
  max-width: 350px;
}

.table[w400] {
  max-width: 400px;
}

.table[w450] {
  max-width: 450px;
}

.table[w650] {
  max-width: 650px;
}

.table[w700] {
  max-width: 700px;
}

.table[w750] {
  max-width: 750px;
}

.table[w800] {
  max-width: 800px;
}

.table[w850] {
  max-width: 850px;
}

.table[w900] {
  max-width: 900px;
}

.table[w950] {
  max-width: 950px;
}

.table[w1000] {
  max-width: 1000px;
}

.table[w1100] {
  max-width: 1100px;
}

.forminput {
  &:required {
    &:valid {
      border-color: $lms-border-valid;
    }

    &:focus {
      &:valid {
        background: 95% 50% rgba(144, 238, 144, 0.4);
      }
    }
  }

  &:invalid {
    border-color: $lms-border-invalid;
  }

  &:focus {
    &:invalid {
      background: 95% 50% lightsalmon;
    }
  }
}

// .ng-valid[required] {
//   border-color: $lms-border-valid;
//   border-left: 3px solid #42a948;
// }

// .ng-valid.required {
//   border-color: $lms-border-valid;
//   border-left: 3px solid #42a948;
// }

// .ng-invalid[required] {
//   border-color: $lms-border-invalid;
//   border-left: 3px solid #a94442b4;
// }

// .ng-invalid.required {
//   border-color: $lms-border-invalid;
//   border-left: 3px solid #a94442b4;
// }

.ng-invalid:not([type="checkbox"]):not([type="radio"]):not(.not) {
  &:not(form) {
    border-color: $lms-border-invalid;
    border-left: 3px solid #a94442b4;
  }
}

.ng-valid:not([type="checkbox"]):not([type="radio"]):not(.not) {
  &:not(form) {
    border-color: $lms-border-valid;
    border-left: 3px solid #42a948;
  }
}

.field-4column-label {
  tr {
    >td {
      height: 37px;
      white-space: nowrap;
      vertical-align: middle;
      text-align: left;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.3rem;

      &:nth-child(odd) {
        max-width: calc(100% - 77%);
        font-weight: 500;
      }

      &:nth-child(even) {
        width: 26%;
        max-width: 26%;
      }
    }
  }
}

thead.table-center-header {
  tr {
    font-weight: 500;

    >td {
      vertical-align: bottom;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
      white-space: nowrap;
      border-bottom: 1px solid $lms-element-bg;
      background-color: $lms-element-bg !important;
      color: $lms-element-text !important;
    }

    >th {
      vertical-align: bottom;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
      white-space: nowrap;
      border-bottom: 1px solid $lms-element-bg;
      background-color: $lms-element-bg !important;
      color: $lms-element-text !important;
    }
  }
}

tr.table-center-header {
  font-weight: 500;

  td {
    vertical-align: bottom;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid $lms-element-bg;
    background-color: $lms-element-bg !important;
    color: $lms-element-text !important;
  }

  th {
    vertical-align: bottom;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid $lms-element-bg;
    background-color: $lms-element-bg !important;
    color: $lms-element-text !important;
  }
}

.flex-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: center;
  align-content: first baseline;
  align-items: baseline;

  >button {
    margin: 0.5em;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    order: 1;
  }
}

.finlogo {
  height: 71px;
}

.center-screen {
  justify-content: center;
  align-items: center;
}

.center-fields {
  >tr {
    >td {
      text-align: center;
      white-space: nowrap;
    }
  }
}

$white_bg: white;
$white-base: rgb(255, 255, 255);

button.btn-icon {
  color: $lms-element-bg;
  background-color: $lms-element-bg;
  position: relative;
  margin: 3px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $white_bg;
    background-color: $primary;
  }
}

button[mat-mdc-icon-button] {
  min-width: 20px;
  background-color: $lms-element-bg;
  color: $lms-element-bg;
  line-height: $lms-line-height;
  letter-spacing: normal;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  position: relative;
  height: 32px;
  padding: 5px;
  line-height: 5px;
}

.svg_icon {
  position: absolute;
  left: 10px;
  fill: #4660dc;
  width: 14px;
  height: 14px;
}

$footer-copyright-bg-color: rgb(50, 50, 50);
$footer-copyright-color: rgb(172, 172, 172);

// Footers
footer {
  &.page-footer {
    bottom: 0;
    color: $white-base;

    .container-fluid {
      width: auto;
    }

    .footer-copyright {
      overflow: hidden;
      background-color: $footer-copyright-bg-color;
      color: $footer-copyright-color;
    }

    a {
      color: $white-base;
    }
  }
}

.mat-expansion-indicator::after {
  width: 10px;
  height: 10px;
  margin-bottom: 5px;
  color: $white-base !important;
}

.agent-landing-container {
  padding: 0.3em;
  background-color: $lms-element-bg;
}

.basic-panel-header {
  height: 33px;
  margin: 0;
  justify-content: center;
  background-color: $lms-element-bg !important;
}

.basic-panel-title {
  justify-content: center;
  color: $lms-element-text;
}

.aligndetails {
  &:host {
    display: block;
  }

  vertical-align: top;
}

.list-item {
  &:hover {
    background-color: rgba(195, 191, 224, 0.267);
  }
}

@media (max-width: 991px) {
  .mat-expansion-panel-body {
    margin-top: 0.25rem;
    padding: 0.25rem 0.01rem 0.01rem !important;
  }
}

.loader-container {
  background-color: inherit;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: absolute;

  -moz-animation: cssAnimation 2s ease-in 15s ease-out;
  /* Firefox */
  /* Safari and Chrome */
  -o-animation: cssAnimation 2s ease-in 15s ease-out;
  /* Opera */
  animation: cssAnimation 2s ease-in 15s ease-out;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@keyframes cssAnimation {
  to {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.spinner {
  margin: 50vh auto 0;
  width: 70px;
  text-align: center;
}

.spinner>div {
  width: 20px;
  height: 20px;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.bounce3 {
  background-color: orange;
}

.spinner .bounce1 {
  background-color: blue;
  animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  background-color: red;
  animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
