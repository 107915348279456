// Toasts

.toast {
  background-color: $toast-background-color;
  border: 0;
  box-shadow: $toast-box-shadow;
  // Additional MDB Angular styles
  margin-bottom: 10px;
  // Additional MDB Angular styles

  .btn-close {
    width: 1.3em;
  }

  // Additional MDB Angular styles
  &:not(.show) {
    display: block;
  }
  // Additional MDB Angular styles
}

.toast-header {
  background-color: $toast-header-background-color;
  border-bottom-width: 2px;

  .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
  }
}

.parent-toast-relative {
  position: relative;
}

.toast-absolute {
  position: absolute;
}

.toast-fixed {
  position: fixed;
  z-index: $zindex-toast;
}

// Additional MDB Angular styles
.toast:not(.showing):not(.show) {
  opacity: 1;
}
// Additional MDB Angular styles

@each $color, $value in $toasts {
  .toast-#{$color} {
    background-color: map-get($value, background-color);
    color: map-get($value, text-color);
    border-color: map-get($value, border-color);
  }
}
