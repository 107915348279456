.carousel-control-prev-icon {
  &::after {
    content: "\f053";
    font-weight: $font-weight-bold;
    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
    font-size: 1.7rem;
  }
}
.carousel-control-next-icon {
  &::after {
    content: "\f054";
    font-weight: $font-weight-bold;
    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
    font-size: 1.7rem;
  }
}
.carousel-indicators {
  [data-mdb-target] {
    @extend [data-bs-target] !optional;
  }
}

.carousel-indicators {
  button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    padding: 0;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: padding-box;
    border: 0;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: $carousel-indicator-opacity;
    @include transition($carousel-indicator-transition);
  }
}
.carousel-dark {
  .carousel-indicators button {
    background-color: $carousel-dark-indicator-active-bg;
  }
}
